export const supportArticleComponent = {
  key: 'supportArticleComponent',
  name: 'Support Article Component',
  siteIdentifier: 'global',

  resolvedMyIssue: 'Resolved my issue',
  easyToUnderstand: 'Information is easy to understand',
  learnedSomethingNew: 'Learned something new',
  somethingElse: 'Something else',
  whatYouLikedAboutContent: 'What did you like about this content?',
  tellMoreAboutYourExperience: 'Tell us more about your experience today?',
  somewhatResolved: 'My issue was somewhat resolved',
  showsProductNotInformation:
    'This shows my product, but not the information I needed',
  onlyCoversSome: 'Page only covers some of the information I needed',
  differentResult: 'I followed the steps but got a different result',
  whatToDoBetter: 'What can we do better?',
  productNotShown: 'My product is not shown',
  notListed: 'My issue is not listed',
  cantOpenLink: 'I can’t open a link within the page.',
  didNotResolveMyIssue: 'Did not resolve my issue',
  hardToUnderstand: 'Information is hard to understand',
  tellUsMoreToGetItRightNextTime:
    'Tell us more about your experience so we can get it right the next time?',
  anythingMoreToShare: 'Is there anything more you would like to share?',
  wouldLoveFeedbackOnPageYouRead:
    'We’d love your feedback on the page you just read!',
  submitButton: 'Submit',
  weAppreciateYourReplyMessage: `We appreciate that you took the time to reply, and we look forward
            to reading your response.`,
};
