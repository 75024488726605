import {
  blockBuilderCommon,
  blockBuilderContentCardComponent,
  blockBuilderFeatureRowComponent,
  blockBuilderPrivacyPolicyComponent,
  blockBuilderProductsComparisonGridComponent,
  blockBuilderBirdeyeReviewWidgetComponent,
  blockBuilderDealerDataComponent,
  blockBuilderImageWithHorizontalTextListComponent,
  blockBuilderSubscriptionGridComponent,
  blockBuilderTestimonialCarouselComponent,
  blockBuilderReferralFormComponent,
  blockBuilderProspectSchedulerComponent,
} from './blockBuilder';
import {
  accountFormCommon,
  accountSettingsCommon,
  addressCommon,
  layoutComponent,
  navigationCommon,
  orderCommon,
  subscriptionCommon,
} from './account';
import { searchCommon } from './searchCommon';
import { articleComponent } from './articleComponent';
import { algoliaSearchBreadcrumbComponent } from './algoliaSearchBreadcrumbComponent';
import { cartCommon } from './cartCommon';
import { dealerCommon } from './dealerCommon';
import { demoComponent } from './demoComponent';
import { featuredUgcVideosComponent } from './featuredUgcVideosComponent';
import { footerCommon } from './footerCommon';
import { contactFormComponent, formMessageBoxComponent } from './form';
import { headerCommon } from './headerCommon';
import { heroComponent } from './heroComponent';
import { notificationBannerCommon } from './notificationBannerCommon';
import { productCommon } from './productCommon';
import { shadcnCommon } from './shadcnCommon';
import { supportArticleComponent } from './supportArticleComponent';
import { carouselTestimonialComponent } from './carouselTestimonialComponent';
import { openChatWidgetComponent } from './openChatWidgetComponent';
import { productAttributesComponent } from './productAttributesComponent';
import { unsupportedBrowserMessageComponent } from './unsupportedBrowserMessageComponent';
import { defaultFormValidationMessagesCommon } from './defaultFormValidationMessagesCommon';
import { breadcrumbComponent } from './breadcrumbComponent';
import { fallbackCommon } from './fallbackCommon';
import { paginationCommon } from './paginationCommon';
import { buttonCommon } from './buttonCommon';

import { dept } from './dept';
import { countriesListCommon } from './countriesListCommon';
import { tailwind } from './tailwind';
import { referralProgramComponent } from './referralProgramComponent';
import { carouselRowProductsClippedComponent } from './carouselRowProductsClippedComponent';
import {
  shopifyOrderFinancialStatus,
  shopifyOrderFulfillmentStatus,
} from './shopifyEnums';
import { skipToContentComponent } from './skipToContentComponent';
import { navSearchComponent } from './navSearchComponent';
import { productModalComponent } from './productModalComponent';
import { verifyAccountComponent } from './verifyAccountComponent';
import { regionsListCommon } from './regionsListCommon';

export const uiComponentsLocalizationData = {
  accountFormCommon,
  accountSettingsCommon,
  addressCommon,
  algoliaSearchBreadcrumbComponent,
  layoutComponent,
  navigationCommon,
  orderCommon,
  subscriptionCommon,
  searchCommon,
  articleComponent,
  cartCommon,
  countriesListCommon,
  regionsListCommon,
  dealerCommon,
  demoComponent,
  featuredUgcVideosComponent,
  footerCommon,
  contactFormComponent,
  formMessageBoxComponent,
  headerCommon,
  heroComponent,
  notificationBannerCommon,
  productCommon,
  shadcnCommon,
  supportArticleComponent,
  carouselTestimonialComponent,
  openChatWidgetComponent,
  productAttributesComponent,
  unsupportedBrowserMessageComponent,
  defaultFormValidationMessagesCommon,
  breadcrumbComponent,
  fallbackCommon,
  paginationCommon,
  buttonCommon,
  referralProgramComponent,
  carouselRowProductsClippedComponent,
  blockBuilderCommon,
  blockBuilderContentCardComponent,
  blockBuilderFeatureRowComponent,
  blockBuilderPrivacyPolicyComponent,
  blockBuilderProductsComparisonGridComponent,
  blockBuilderBirdeyeReviewWidgetComponent,
  blockBuilderDealerDataComponent,
  blockBuilderImageWithHorizontalTextListComponent,
  blockBuilderSubscriptionGridComponent,
  blockBuilderTestimonialCarouselComponent,
  blockBuilderReferralFormComponent,
  blockBuilderProspectSchedulerComponent,

  ...dept,
  ...tailwind,

  shopifyOrderFulfillmentStatus,
  shopifyOrderFinancialStatus,
  skipToContentComponent,
  navSearchComponent,
  productModalComponent,
  verifyAccountComponent,
};

export const uiComponentsLocalizationStructure = uiComponentsLocalizationData;

export { useUiComponentsInternationalContext } from './useUiComponentsInternationalContext';
