export const footerCommon = {
  key: 'footerCommon',
  name: 'Footer Common',
  siteIdentifier: 'global',

  contactUsHeader: 'Contact Us',
  sendUsMessageLink: 'Send Us a Message',
  allRightsReserved: 'All Rights Reserved.',
  openCookieSettingsModalAriaLabel: 'Open cookie settings modal',
  cookieSettingsButton: 'Cookie Settings',
  facebookLinkAriaLabel: 'Facebook',
  instagramLinkAriaLabel: 'Instagram',
  youTubeLinkAriaLabel: 'YouTube',
  xLinkAriaLabel: 'X',
  pinterestLinkAriaLabel: 'Pinterest',
};
