const accountNavigation = {
  logoutButton: 'Logout',
  welcomeLabel: 'Welcome, {firstName}',
  ordersTabLabel: 'Orders',
  subscriptionsTabLabel: 'Subscriptions',
  addressesTabLabel: 'Addresses',
  myAccountTabLabel: 'My Account',
};

const accountSubscription = {
  modelNumberLabel: 'Model number: {sku}',
  quantityLabel: 'Quantity',
  quantityWithParameterLabel: 'Quantity: {quantity}',
  nextShippingDateLabel: 'Next shipping date: {scheduledAt}',
  shipsEveryLabel: 'Ships every: {frequency}',
  monthsLabel: 'months',
  shipsToLabel: 'Ships to: {address}',
  pricePerItemLabel: 'Price per item: {price}',
  activeSubscriptionsLabel: 'Active Subscriptions',
  noSubscriptionsMessageLabel: `It looks like you're not subscribed to any products at the
moment. Let's fix that.`,
  clickHereLabel: 'Click here',
  shopAllProductsLabel: 'to shop all products.',
};

const cancelButton = {
  cancelSubscriptionLabel: 'Cancel Subscription',
  confirmCancellationLabel: 'Are you sure you want to cancel?',
  cancellationReasonLabel: 'Cancellation Reason',
  reasonForCancellationOptionLabel: 'Why do you want to cancel?',
  additionalFeedbackLabel: 'What else do we need to know?',
  feedbackLabel: `Please let us know what we can do to better serve you and all our wonderful customers.`,
  keepSubscriptionLabel: 'Keep Subscription',
  noLongerUseReasonLabel: 'I no longer use this product',
  expensiveReasonLabel: 'Subscription is too expensive',
  anotherSubscriptionReasonLabel:
    'Using another company’s subscription service',
  alreadyHaveReasonLabel: 'I already have more than I need',
  otherLabel: 'Other',
};

const legacySubscriptions = {
  loadingMessage: 'Loading...',
  updateSubscriptionsButton: 'Update Subscriptions',
};

const manageSubscriptionButton = {
  manageSubscriptionButton: 'Manage Subscription',
  editSubscriptionSettingsLabel: 'Edit subscription settings',
  shippingAddressLabel: 'Shipping Address',
  addNewAddressLabel: 'Add new address',
  frequencyLabel: 'Frequency',
  dontUpdateSubscriptionButton: "Don't update subscription",
  updateSubscriptionButton: 'Update subscription',
};

const portalButton = {
  defaultChildrenLink: 'Open Subscription Management Portal',
};

const skipDeliveryButton = {
  skipNextDeliveryButton: 'Skip Next Delivery',
  confirmSkipDeliveryLabel: 'Are you sure you want to skip your next delivery?',
  keepNextDeliveryButton: 'Keep Next Delivery',
};

const skipOrderButton = {
  successToastMessage: 'Your changes have been saved.',
  errorToastMessage:
    'Your changes could not be saved at this time. Please try again later.',
  skipNextOrderButton: 'Skip Next Order',
  orderSkipMLabel: `Your upcoming order on {upcomingDate} will be skipped. Your next order
will be on`,
  skipThisOrderButton: 'Skip This Order',
};

const subscriptionAutoship = {
  subscriptionPaymentErrorMessage:
    'Please select a card with a valid expiration date',
  subscriptionInvalidDateErrorMessage:
    'Please select a valid date for your next order.',
  saveChangesButton: 'Save Changes',
  savingButton: 'Saving',
  quantityTotalMessage: `You'll be charged {quantityTotal} on your next billing date.`,
  orderFrequencyLabel: 'Order Frequency',
  chargeAndShipEveryLabel: 'Charge and ship every',
  weeksLabel: 'Weeks',
  monthsLabel: 'Months',
  nextOrderDateLabel: 'Next Order Date',
};

const subscriptionLte = {
  billingFrequencyLabel: 'Billing Frequency',
  alertPriceAlertLabel: `You'll be charged {alertPrice} on your next billing date.`,
  nextBillingDateLabel: 'Next Billing Date',
};

const defaultMessages = {
  defaultErrorMessage: `Your changes could not be saved at this time. Please try again later.`,
  defaultSuccessMessage: `Your changes have been saved`,
};

const subscriptionsCancel = {
  cancellingLabel: 'Cancelling',
  successCancelToastMessage: 'Your subscription has been cancelled.',
  errorCancelToastTitle:
    'We could not complete your request at this time. Please try again later.',
  nextBillingDateLabel: 'Next Billing Date',
  cancellingReasonLabel: `Please help us process your request by telling us why you're
cancelling.`,
  cancelDescriptionLabel: 'What else do we need to know?',
  featuresWillDeactivatedLabel:
    'All collar features will be deactivated without a subscription.',
  subscriptionWillCanceledLabel:
    'All future billing of this subscription will be canceled.',
  newSubscriptionLabel: `You can start a new subscription at any time by selecting
Activate Collar in the My PetSafe app.`,
  cancelYourSubscriptionLabel: 'Cancel your subscription?',
};

const subscriptionsList = {
  managePaymentMethodsButton: 'Manage Payment Methods',
};

const messageBox = {
  messageBoxTitle: 'No Current Orders',
  messageBoxBodyText: `Orders are processed periodically throughout the day. Once the order
has processed for shipping, it cannot be canceled or changed. For
immediate assistance, contact Customer Care at`,
  messageBoxBodyEnding: `We are open Monday through Friday 8 am - 8 pm ET and Saturday 9 am -
5 pm ET.`,
};

export const accountDeptComponents = {
  key: 'accountDeptComponents',
  name: 'Account Dept-Components',
  siteIdentifier: 'global',

  ...accountNavigation,
  ...accountSubscription,
  ...cancelButton,
  ...legacySubscriptions,
  ...manageSubscriptionButton,
  ...portalButton,
  ...skipDeliveryButton,
  ...skipOrderButton,
  ...subscriptionAutoship,
  ...subscriptionLte,
  ...defaultMessages,
  ...subscriptionsCancel,
  ...subscriptionsList,
  ...messageBox,
};
