export const dealerCommon = {
  key: 'dealerCommon',
  name: 'Dealer Common',
  siteIdentifier: 'global',

  weAreSorryHeader: "We're sorry",
  infoUnavailableForDealer:
    '{planType} information is unavailable for your local dealer:',
  contactUsForAssistance:
    'Please {sendUsMessageLink} or give us a call at {phoneLink} for further assistance.',
  sendUsMessageLink: 'send us a message',
  visitCustomerResourcePageLink: 'Close and visit customer resources page.',
  selectLocalDealer: 'Please select your local dealer',
  closeDealerSearchAriaLabel: 'Close Dealer Search',
  toProvideRelevantInfo: `So that we can provide you with accurate information and
              subscription options for your area.`,
  searchByZipCodeLabel: 'Search by ZIP or Postal Code',
  submitAriaLabel: 'Submit',
  enterValidZipCodeValidationMessage: 'Please enter a valid ZIP or Postal Code',
  noLocalDealerMessage: `We couldn’t find an authorized dealer in that area. Please check
              the ZIP/postal code you submitted. If correct, {sendUsMessageLink}
              or give us a call at {phoneLink} for further assistance.`,
  findDealerHeader: 'Find a Dealer',
  searchAllDealersButton: 'Search all dealers',
  findLocalDealerButton: 'Find your Local Dealer',
  yourLocalDealerButton: 'Your local dealer',
  selectedDealer: 'Selected Dealer',
  tabFocusTrapButton: 'Tab focus trap',
  sendUsMessageInitCapLink: 'Send us a message',
  visitDealerPageLink: 'Visit Dealer Page',
  selectThisDealerButton: 'Select this dealer',
  planUnavailableHeader: `We're sorry`,
  planUnavailableDescription:
    '{planType} information is unavailable for your local dealer: {name}.',
  planUnavailableCallTip:
    'Please give us a call at {number} for further assistance.',
  searchNotFoundMessage:
    'We couldn’t find an authorized dealer in that area. Please check the ZIP/postal code you submitted. If correct, give us a call at {phone} for further assistance.',
};
