import React, { createContext, ReactNode, useMemo } from 'react';
import { AppSiteSettings } from '@rsc/models/AppSanityTypes';
import { GLOBAL_PRODUCTS } from '@rsc/utils/constants';
import { useInternationalContext } from '@rsc/gatsby-localization-helpers';

export type SiteSettingsContextType = {
  siteSettings: AppSiteSettings;
  siteIdentifier: string;
  algoliaSearchSettings: {
    productIndexName: string;
    blogIndexName: string;
    getAlgoliaProductsFilter: () => string;
    getAlgoliaBlogsFilter: () => string;
  };
};

const SiteSettingsContext = createContext<SiteSettingsContextType | undefined>(
  undefined
);

export type SiteSettingsProviderProps = {
  children: ReactNode;
  siteIdentifier: string;
  useSiteSettings: (locale: string) => AppSiteSettings;
};

function SiteSettingsProvider({
  children,
  useSiteSettings,
  siteIdentifier,
}: Readonly<SiteSettingsProviderProps>) {
  const { locale } = useInternationalContext({});

  const isAlgoliaSearchLocalized = ['sportdog', 'petsafe'].includes(
    siteIdentifier
  );

  const siteSettings = useSiteSettings(locale);

  const memoizedValue = useMemo(() => {
    const getAlgoliaLanguageFilter = () =>
      isAlgoliaSearchLocalized && locale ? `language:${locale}` : '';

    const getAlgoliaProductsFilter = () => {
      const additionalLanguageFilter =
        isAlgoliaSearchLocalized && locale
          ? `AND ${getAlgoliaLanguageFilter()}`
          : '';

      return `NOT canBeShown:false ${additionalLanguageFilter}`;
    };

    return {
      siteSettings,
      siteIdentifier,
      algoliaSearchSettings: {
        productIndexName: isAlgoliaSearchLocalized
          ? GLOBAL_PRODUCTS
          : 'products',
        blogIndexName: 'blogs',
        getAlgoliaProductsFilter,
        getAlgoliaBlogsFilter: getAlgoliaLanguageFilter,
      },
    };
  }, [siteSettings, siteIdentifier, isAlgoliaSearchLocalized, locale]);

  return (
    <SiteSettingsContext.Provider value={memoizedValue}>
      {children}
    </SiteSettingsContext.Provider>
  );
}

export { SiteSettingsProvider, SiteSettingsContext };
