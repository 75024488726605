import { useUiComponentsInternationalContext } from '@rsc/static-translations';

export const filteredVariants = variants => {
  const acceptedLifecyle = ['Available', 'Coming Soon', 'Discontinued'];
  return variants.filter(v => acceptedLifecyle.includes(v?.variantLifecycle));
};

export const useAddToCartBtnTxt = () => {
  const { tt } = useUiComponentsInternationalContext();

  return (lifecycle: string) => {
    switch (lifecycle) {
      case 'Coming Soon':
        return tt(s => s.productOptionsComponent.comingSoonButton);
      case 'Obsolete':
        return tt(s => s.productOptionsComponent.discontinuedButton);
      default:
        return tt(s => s.productOptionsComponent.outOfStockButton);
    }
  };
};

export const variantOptionsFilter = variants => {
  if (variants.length === 1) return [];

  const modifiedOptions = variants
    .map(variant => variant?.selectedOptions)
    .flat(1)
    .reduce((res, { name, value }) => {
      let bin = res.find(e => e.name === name);

      if (!bin) {
        bin = { name, values: new Set() };
        res.push(bin);
      }
      bin.values.add(value);
      return res;
    }, []);

  modifiedOptions.forEach(e => {
    e.values = [...e.values];
  });

  return modifiedOptions;
};
