export const outOfStockFormComponent = {
  key: 'outOfStockFormComponent',
  name: 'Out Of Stock Form Component',
  siteIdentifier: 'global',

  outOfStockMessageLabel: `We'll be in touch when this is back in stock.`,
  emailValidationMessage: 'Please re-check your email address.',
  requiredValidationMessage: 'Email address required',
  errorMessage:
    'Out of Stock form submission failed. Please refresh the page or try again later.',
  formLabel: `Enter your email address and we'll notify you when this item is
back in stock.`,
  submitButtonAreaLabel: 'Out of Stock Submit Button',
};
