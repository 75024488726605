const productManuals = {
  productManualsPrompt: 'Looking for product manuals?',
  browseProductsLabel: 'Browse for your product here',
  downloadsLabel: 'Downloads',
};

const carousel = {
  carouselRowCompareLabel: 'CarouselRowCompare',
  previousButtonAriaLabel: 'Previous',
  nextButtonAriaLabel: 'Next',
};

const filter = {
  filterByGroupAriaLabel: 'Filter by {group}',
  filterByGroupAndTitleAriaLabel: `Filter {group} by {filterTitle}`,
  clearAllFiltersAriaLabel: 'Clear all filters',
  filtersLabel: 'Filters',
  clearAllLabel: 'Clear all',
  resultsLabel: 'Results',
  filterSortButton: 'Filter & Sort',
  closeButton: 'close',
  removeFilterAriaLabel: 'Remove {filter} filter',
};

const viewAll = {
  viewAllProductsLabel: 'View all {title} products',
};

const price = {
  originalPriceLabel: 'Original price {comparePrice} now {minPrice}',
  startingAtLabel: 'Starting at',
};

const plan = {
  subscriptionPlanAriaLabel:
    'Select subscription plan {price} and go to checkout',
  selectPlanButton: 'Select Plan',
};

const search = {
  searchByNameLabel: 'Search by name',
  searchBySKULabel: 'Search by SKU/Part Number',
  SKUsLabel: 'SKUs',
  backToTopButton: 'Back to Top',
};

const isotype = {
  petSafeIsotypeLabel: 'PetSafe isotype',
};

const authors = {
  expertBadgeAlt: 'Icon Expert Badge',
  authorsArticlesLabel: 'Articles by this Author',
};

const innotek = {
  manualsLabel: 'Manuals',
};

const validation = {
  emailValidationMessage:
    'Please use a valid email address such as user@example.com',
  failPlanSelectMessage: 'Selecting Plan Failed',
};

const blogBlock = {
  allLabel: 'All',
  readMoreLabel: 'Read More',
};

const sorters = {
  newestLabel: 'Newest',
  oldestLabel: 'Oldest',
  aToZLabel: 'A to Z',
  zToALabel: 'Z to A',
};

const collectionSorters = {
  featuredCollectionSorterLabel: 'Featured',
  newestCollectionSorterLabel: 'Newest',
  priceLowToHighCollectionSorterLabel: 'Price: Low to High',
  priceHighToLowCollectionSorterLabel: 'Price: High to Low',
};

const subscriptionGrid = {
  petNameLabel: 'Pet Name',
  emailLabel: 'Email',
  iotUserIdLabel: 'IOT User ID',
  iotGroupIdLabel: 'IOT Group ID',
  deviceIdLabel: 'Device ID',
  collarIdLabel: 'Collar ID',
  thingNameLabel: 'Thing Name',
  petIdLabel: 'Pet ID',
};

const gridPress = {
  gridPressResultsLabel: '{count} Results',
};

export const blocksDeptComponents = {
  key: 'blocksDeptComponents',
  name: 'Blocks Dept-Components',
  siteIdentifier: 'global',

  ...productManuals,
  ...carousel,
  ...filter,
  ...viewAll,
  ...price,
  ...plan,
  ...search,
  ...isotype,
  ...authors,
  ...innotek,
  ...validation,
  ...blogBlock,
  ...sorters,
  ...collectionSorters,
  ...subscriptionGrid,
  ...gridPress,
};
