import React, {
  useState,
  useContext,
  useEffect,
  createContext,
  useMemo,
  ReactNode,
} from 'react';
import { GraphQLClient } from 'graphql-request';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { LOGIN_REDIRECT_QUERY_PARAM_KEY } from '@rsc/constants/localStorageKeys';
import { LocaleCurrencyEnum } from '@rsc/models/LocaleCurrencyEnum';
import { useLocalizedNavigation } from '@rsc/gatsby-localization-helpers';
import { LocaleContext } from './LocaleContext';

export type ShopifyCustomerType = {
  isAuthenticated: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  tags?: string[];
  [key: string]: any;
};
export type ShopifyAddressType = {
  address1: string;
  address2?: string;
  city: string;
  company?: string;
  country: string;
  firstName: string;
  lastName: string;
  phone?: string;
  province?: string;
  zip: string;
};

export type ShopifyConfigType = {
  customerKey: string;
  customerTokenKey: string;
  customerTokenExpiresAtKey: string;
  checkoutBaseUrl: string;
  apiVersion: string;
  accessToken: string;
};

export type CustomerContextType = {
  customer: ShopifyCustomerType | null;
  loginWithMultipass: (multipassToken: string) => void;
  fetchCustomerOrders: (
    first: number,
    last: number,
    after: string,
    before: string
  ) => Promise<any>;
  logoutCustomer: () => Promise<void>;
  deleteAddress: (id: string) => Promise<boolean>;
  editAddress: (
    id: string,
    addressObject: ShopifyAddressType
  ) => Promise<boolean>;
  addAddress: (address: ShopifyAddressType) => Promise<boolean>;
  updateCustomer: (customerObject: any) => Promise<void>;
  updateCustomerDefaultAddress: (addressId: string) => Promise<any>;
  fetchCustomerAddresses: () => Promise<any>;
  isAuthenticated: () => boolean;
  isProStaff: () => boolean;
  saveCustomer: (customerObject: ShopifyCustomerType) => void;
  shopifyClient: GraphQLClient;
  getMagentoSubscriptions: () => Promise<any[]>;
  refreshCognitoIdToken: () => Promise<void>;
  checkCognitoIdExpiry: () => Promise<void>;
  retrieveAndSaveCustomerAccessToken: (multipassToken: string) => Promise<void>;
  renewCustomerAccessToken: (customerAccessToken: string) => Promise<void>;
};
export const CustomerContext = createContext<CustomerContextType | undefined>(
  undefined
);

const isBrowser = typeof window !== 'undefined';

const PRO_PROGRAM_CUSTOMER_TAG = 'pro program';

const defaultState = {
  isAuthenticated: false,
};

const setDefaultState = (shopifySettings: ShopifyConfigType) => {
  try {
    if (isBrowser) {
      const customerObject = JSON.parse(
        window.localStorage.getItem(shopifySettings.customerKey)
      );
      const customerAccessTokenExpiresAt = window.localStorage.getItem(
        shopifySettings.customerTokenExpiresAtKey
      );

      // Check if token is expired, if one exists - then renew it and set it as initial state.
      if (
        customerObject &&
        customerAccessTokenExpiresAt > new Date().toISOString()
      ) {
        return customerObject;
      }
      return defaultState;
    }
  } catch (error) {
    console.error(error);
  }
  return defaultState;
};

export type CustomerProviderProps = {
  children: ReactNode;
  shopifySettings?: ShopifyConfigType | null;
};

function CustomerProvider({
  children,
  shopifySettings,
}: CustomerProviderProps) {
  const localeContext = useContext(LocaleContext);
  const currency = localeContext?.currency || LocaleCurrencyEnum.USD;
  const navigate = useLocalizedNavigation();

  const shopifySettingsForCurrency = useMemo(() => {
    if (currency === LocaleCurrencyEnum.CAD) {
      return {
        checkoutBaseUrl: `https://${process.env.GATSBY_SHOPIFY_CAD_DOMAIN_NAME}`,
        apiVersion: process.env.GATSBY_SHOPIFY_API_VERSION,
        accessToken: process.env.GATSBY_SHOPIFY_CAD_ACCESS_TOKEN,
        customerKey: 'customer_cad',
        customerTokenKey: 'customer_access_token_cad',
        customerTokenExpiresAtKey: 'customer_access_token_expires_at_cad',
      };
    }
    return (
      shopifySettings ?? {
        checkoutBaseUrl: `https://${process.env.GATSBY_SHOPIFY_DOMAIN_NAME}`,
        apiVersion: process.env.GATSBY_SHOPIFY_API_VERSION,
        accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
        customerKey: 'customer',
        customerTokenKey: 'customer_access_token',
        customerTokenExpiresAtKey: 'customer_access_token_expires_at',
      }
    );
  }, [currency, shopifySettings]);

  const [customer, setCustomer] = useState(
    setDefaultState(shopifySettingsForCurrency)
  );

  const shopifyClient = new GraphQLClient(
    `${shopifySettingsForCurrency.checkoutBaseUrl}/api/${shopifySettingsForCurrency.apiVersion}/graphql.json`,
    {
      headers: {
        'X-Shopify-Storefront-Access-Token':
          shopifySettingsForCurrency.accessToken,
      },
    }
  );

  const saveCustomer = (customerObject: ShopifyCustomerType) => {
    setCustomer({
      ...customerObject,
    });

    if (isBrowser) {
      window.localStorage.setItem(
        shopifySettingsForCurrency.customerKey,
        JSON.stringify({
          ...customerObject,
        })
      );
    }
  };

  const getCustomerObject = async () => {
    if (isBrowser) {
      const customerAccessToken = window.localStorage.getItem(
        shopifySettingsForCurrency.customerTokenKey
      );

      const shopifyObject = await shopifyClient
        .request(
          `
          query CustomerQuery {
            customer(customerAccessToken: "${customerAccessToken}") {
              acceptsMarketing
              createdAt
              email
              defaultAddress {
                id
              }
              displayName
              email
              firstName
              id
              lastName
              phone
              updatedAt
              tags
            }
          }
        `
        )
        .then(response => {
          if (response?.customer?.email?.length > 0) {
            const authenticatedCustomer = {
              ...response.customer,
              isAuthenticated: true,
            };
            saveCustomer(authenticatedCustomer);
            window?.dataLayer?.push({
              customerEmail: authenticatedCustomer.email,
            });
            return true;
          }
          return false;
        })
        .catch(error => {
          setCustomer(null);
          console.error(error);
          return false;
        });
      return shopifyObject;
    }
    return false;
  };

  const renewCustomerAccessToken = async (customerAccessToken: string) => {
    await shopifyClient
      .request(
        `
        mutation customerAccessTokenRenew($customerAccessToken: String!) {
          customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
            customerAccessToken {
              accessToken
              expiresAt
            }
            userErrors {
              field
              message
            }
          }
        }
      `,
        {
          customerAccessToken,
        }
      )
      .then(async ({ customerAccessTokenCreateWithMultipass }) => {
        if (
          customerAccessTokenCreateWithMultipass?.customerUserErrors.length > 0
        ) {
          console.error(
            'Error(s):',
            customerAccessTokenCreateWithMultipass.customerUserErrors
          );
        }

        if (
          customerAccessTokenCreateWithMultipass?.customerAccessToken &&
          isBrowser
        ) {
          window.localStorage.setItem(
            shopifySettingsForCurrency.customerTokenKey,
            customerAccessTokenCreateWithMultipass.customerAccessToken
              .accessToken
          );
          window.localStorage.setItem(
            shopifySettingsForCurrency.customerTokenExpiresAtKey,
            customerAccessTokenCreateWithMultipass.customerAccessToken.expiresAt
          );
          await getCustomerObject();
        }
      });
  };

  const checkCustomerAccessToken = async () => {
    try {
      if (!isBrowser) {
        return;
      }

      const customerObject = JSON.parse(
        window.localStorage.getItem(shopifySettingsForCurrency.customerKey)
      );
      const customerAccessToken = window.localStorage.getItem(
        shopifySettingsForCurrency.customerTokenKey
      );
      const customerAccessTokenExpiresAt = window.localStorage.getItem(
        shopifySettingsForCurrency.customerTokenExpiresAtKey
      );

      if (!customerAccessToken) {
        return;
      }
      // Check if customer_access_token_expires_at is valid
      if (
        customerAccessTokenExpiresAt &&
        customerAccessTokenExpiresAt > new Date().toISOString()
      ) {
        if (customerObject?.isAuthenticated) {
          saveCustomer(customerObject);
        } else {
          await getCustomerObject();
        }
      } else {
        await renewCustomerAccessToken(customerAccessToken);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMagentoSubscriptions = async () => {
    if (isBrowser) {
      try {
        const token = window?.localStorage?.getItem('cognito_id_token');
        if (token) {
          const response = await fetch(
            process.env.GATSBY_GET_SUBSCRIPTIONS_ENDPOINT,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const body = await response.json();
          return body?.subscriptions || [];
        }
      } catch (err) {
        console.log(err);
      }
    }
    return [];
  };

  const refreshCognitoIdToken = async () => {
    if (isBrowser) {
      const { CognitoUserPool, CognitoUser, CognitoRefreshToken } =
        await import('amazon-cognito-identity-js');

      const cognitoRefreshToken = window.localStorage.getItem(
        'cognito_refresh_token'
      );
      if (cognitoRefreshToken && customer?.email) {
        const poolData = new CognitoUserPool({
          UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
          ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
        });

        const userData = {
          Username: customer.email,
          Pool: poolData,
        };
        const cognitoUser = new CognitoUser(userData);
        const token = new CognitoRefreshToken({
          RefreshToken: cognitoRefreshToken,
        });

        try {
          const session: CognitoUserSession = await new Promise(
            (resolve, reject) => {
              cognitoUser.refreshSession(
                token,
                (err, data: CognitoUserSession | null) => {
                  if (err) {
                    reject(new Error(err));
                  } else {
                    resolve(data);
                  }
                }
              );
            }
          );
          const accessToken = session?.getAccessToken();
          const idToken = session?.getIdToken();
          const refreshToken = session?.getRefreshToken();

          const jwtAccessToken = accessToken?.getJwtToken();
          if (jwtAccessToken) {
            window.localStorage.setItem('cognito_access_token', jwtAccessToken);
          }
          const jwtIdToken = idToken?.getJwtToken();
          if (jwtIdToken) {
            window.localStorage.setItem('cognito_id_token', jwtIdToken);
          }
          const expirationIdToken = idToken?.getExpiration()?.toString();
          if (expirationIdToken) {
            localStorage.setItem('cognito_id_token_expiry', expirationIdToken);
          }
          const jwtRefreshToken = refreshToken?.getToken();
          if (jwtRefreshToken) {
            window.localStorage.setItem(
              'cognito_refresh_token',
              jwtRefreshToken
            );
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const checkCognitoIdExpiry = async () => {
    if (isBrowser) {
      const cognitoIdTokenExpiry = window.localStorage.getItem(
        'cognito_id_token_expiry'
      );

      if (cognitoIdTokenExpiry) {
        const currentTime = Math.floor(Date.now() / 1000);
        const threshold = 10 * 60;

        if (parseInt(cognitoIdTokenExpiry, 10) - currentTime <= threshold) {
          await refreshCognitoIdToken();
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (customer) await checkCustomerAccessToken();
    })();
  }, [customer?.isAuthenticated]);

  return (
    <CustomerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        customer,
        logoutCustomer: async () => {
          if (isBrowser) {
            const customerAccessToken = window.localStorage.getItem(
              shopifySettingsForCurrency.customerTokenKey
            );

            window.localStorage.removeItem(
              shopifySettingsForCurrency.customerKey
            );
            window.localStorage.removeItem(
              shopifySettingsForCurrency.customerTokenKey
            );

            // Remove cognito data
            window.localStorage.removeItem('cognito_id_token');
            window.localStorage.removeItem('cognito_access_token');
            window.localStorage.removeItem('cognito_refresh_token');
            window.localStorage.removeItem('cognito_id_token_expiry');

            // Reinitialize a new checkout on logout
            window.localStorage.removeItem('_checkoutId');
            window.localStorage.removeItem('checkoutActive');

            const logoutBaseUrl = shopifySettingsForCurrency.checkoutBaseUrl;

            const logoutLink = `${logoutBaseUrl}/account/logout?return_url=/gatsby`;
            if (customerAccessToken) {
              await shopifyClient
                .request(
                  `
                  mutation customerAccessTokenDelete($customerAccessToken: String!) {
                    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                      deletedAccessToken
                      deletedCustomerAccessTokenId
                      userErrors {
                        field
                        message
                      }
                    }
                  }
                  `,
                  {
                    customerAccessToken,
                  }
                )
                .finally(() => {
                  window.location.href = logoutLink;
                  // Shopify /gatsby endpoint is a redirect in Shopify to Gatsby Homepage
                });
            }
            window.location.href = logoutLink;
          }
        },
        retrieveAndSaveCustomerAccessToken: async (multipassToken: string) => {
          await shopifyClient
            .request(
              `
                mutation customerAccessTokenCreateWithMultipass(
                  $multipassToken: String!
                ) {
                  customerAccessTokenCreateWithMultipass(
                    multipassToken: $multipassToken
                  ) {
                    customerAccessToken {
                      accessToken
                      expiresAt
                    }
                    customerUserErrors {
                      code
                      field
                      message
                    }
                  }
                }
              `,
              {
                multipassToken,
              }
            )
            .then(async ({ customerAccessTokenCreateWithMultipass }) => {
              console.log(
                'customerAccessTokenCreateWithMultipass',
                customerAccessTokenCreateWithMultipass
              );

              if (
                customerAccessTokenCreateWithMultipass?.customerUserErrors
                  ?.length > 0
              ) {
                console.error(
                  'Error(s):',
                  customerAccessTokenCreateWithMultipass.customerUserErrors[0]
                );
              }

              if (
                customerAccessTokenCreateWithMultipass?.customerAccessToken &&
                isBrowser
              ) {
                window.localStorage.setItem(
                  shopifySettingsForCurrency.customerTokenKey,
                  customerAccessTokenCreateWithMultipass.customerAccessToken
                    .accessToken
                );
                window.localStorage.setItem(
                  shopifySettingsForCurrency.customerTokenExpiresAtKey,
                  customerAccessTokenCreateWithMultipass.customerAccessToken
                    .expiresAt
                );
                await getCustomerObject();
              }
            });
        },
        loginWithMultipass: (multipassToken: string) => {
          window.location.href = `${shopifySettingsForCurrency.checkoutBaseUrl}/account/login/multipass/${multipassToken}`;
        },
        deleteAddress: async id => {
          try {
            const response = await shopifyClient.request(
              `
              mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
                customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
                  customerUserErrors {
                    code
                    field
                    message
                  }
                  deletedCustomerAddressId
                }
              }
              `,
              {
                customerAccessToken:
                  isBrowser &&
                  window.localStorage.getItem(
                    shopifySettingsForCurrency.customerTokenKey
                  ),
                id,
              }
            );

            const { customerUserErrors } = response.customerAddressDelete;

            if (customerUserErrors?.length > 0) {
              console.error('Error(s):', customerUserErrors);
              return false;
            }
            return true;
          } catch (error) {
            console.error(error);
            throw new Error('Could not delete address');
          }
        },
        editAddress: async (id, addressObject) => {
          try {
            const { customerUserErrors } = await shopifyClient.request(
              `
              mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
                customerAddressUpdate(
                  customerAccessToken: $customerAccessToken
                  id: $id
                  address: $address
                ) {
                  customerAddress {
                    id
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }
              `,
              {
                customerAccessToken:
                  isBrowser &&
                  window.localStorage.getItem(
                    shopifySettingsForCurrency.customerTokenKey
                  ),
                id,
                address: addressObject,
              }
            );
            if (customerUserErrors?.length > 0) {
              console.error('Error(s):', customerUserErrors);
              return false;
            }
            return true;
          } catch (error) {
            console.error(error);
            throw new Error('Address could not be updated');
          }
        },
        addAddress: async address => {
          try {
            const { customerAddressCreate } = await shopifyClient.request(
              `
              mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
                customerAddressCreate(
                  customerAccessToken: $customerAccessToken
                  address: $address
                ) {
                  customerAddress {
                    id
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }
              `,
              {
                customerAccessToken:
                  isBrowser &&
                  window.localStorage.getItem(
                    shopifySettingsForCurrency.customerTokenKey
                  ),
                address,
              }
            );
            const { customerUserErrors } = customerAddressCreate;
            if (customerUserErrors?.length > 0) {
              console.error('Error(s):', customerUserErrors);
              return false;
            }
            return true;
          } catch (error) {
            console.error(error);
            throw new Error('Address could not be added');
          }
        },
        updateCustomer: async customerObject => {
          await shopifyClient
            .request(
              `
            mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
              customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
                customer {
                  acceptsMarketing
                  createdAt
                  displayName
                  email
                  firstName
                  lastName
                  phone
                  updatedAt
                  id
                }
                customerAccessToken {
                  accessToken
                  expiresAt
                }
                customerUserErrors {
                  code
                  field
                  message
                }
              }
            }
            `,
              {
                customerAccessToken:
                  isBrowser &&
                  window.localStorage.getItem(
                    shopifySettingsForCurrency.customerTokenKey
                  ),
                customer: customerObject,
              }
            )
            .then(({ customerUpdate }) => {
              const { customerAccessToken, customerUserErrors } =
                customerUpdate;
              if (customerUserErrors?.length > 0) {
                if (isBrowser) {
                  navigate('/account/settings', {
                    state: {
                      customerUserErrors: [
                        {
                          error:
                            customerUserErrors[0]?.message ||
                            JSON.stringify(customerUserErrors),
                        },
                      ],
                    },
                  });
                  return;
                }
              }

              if (customerAccessToken && isBrowser) {
                window.localStorage.setItem(
                  shopifySettingsForCurrency.customerTokenKey,
                  customerAccessToken.accessToken
                );
                window.localStorage.setItem(
                  shopifySettingsForCurrency.customerTokenExpiresAtKey,
                  customerAccessToken.expiresAt
                );
              }

              if (customerUpdate.customer) {
                const authenticatedCustomer = {
                  ...customerUpdate.customer,
                  isAuthenticated: true,
                };

                saveCustomer(authenticatedCustomer);
                if (isBrowser) {
                  navigate('/account/settings', {
                    state: {
                      customerUserErrors: [],
                    },
                  });
                }
              }
            });
        },
        updateCustomerDefaultAddress: async addressId => {
          await shopifyClient
            .request(
              `
              mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
                customerDefaultAddressUpdate(
                  customerAccessToken: $customerAccessToken
                  addressId: $addressId
                ) {
                  customer {
                    id
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }
            `,
              {
                customerAccessToken:
                  isBrowser &&
                  window.localStorage.getItem(
                    shopifySettingsForCurrency.customerTokenKey
                  ),
                addressId,
              }
            )
            .then(async ({ customerDefaultAddressUpdate }) => {
              const { customerUserErrors } = customerDefaultAddressUpdate;
              if (customerUserErrors?.length > 0) {
                console.error('Error(s):', customerUserErrors);
              }
              return customerDefaultAddressUpdate?.customer;
            });
        },
        fetchCustomerAddresses: async () => {
          const GET_ADDRESSES = `
            query CustomerAddressQuery($customerAccessToken: String!) {
              customerAddresses: customer(customerAccessToken: $customerAccessToken) {
                defaultAddress {
                  id
                }
                addresses(first: 250) {
                  edges {
                    node {
                      id
                      address1
                      address2
                      city
                      company
                      lastName
                      firstName
                      country
                      country_code: countryCodeV2
                      name
                      zip
                      province
                      phone
                    }
                  }
                }
              }
            }
          `;

          if (isBrowser) {
            const data = await shopifyClient.request(GET_ADDRESSES, {
              customerAccessToken: window.localStorage.getItem(
                shopifySettingsForCurrency.customerTokenKey
              ),
            });

            return data;
          }
          return null;
        },
        fetchCustomerOrders: async (
          first: number,
          last: number,
          after: string,
          before: string
        ) => {
          const GET_ORDERS = `
            query CustomerQuery(
              $customerAccessToken: String!
              $first: Int
              $last: Int
              $before: String
              $after: String
            ) {
              customer(customerAccessToken: $customerAccessToken) {
                orders(
                  reverse: true
                  first: $first
                  last: $last
                  before: $before
                  after: $after
                ) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  edges {
                    cursor
                    node {
                      id
                      name
                      processedAt
                      financialStatus
                      fulfillmentStatus
                      customAttributes {
                        key
                        value
                      }
                      currentTotalPrice {
                        amount
                      }
                      shippingAddress {
                        address1
                        address2
                        city
                        company
                        country
                        name
                        phone
                        province
                        zip
                      }
                      lineItems(first: 250) {
                        edges {
                          node {
                            quantity
                            title
                            variant {
                              requiresShipping
                              productImage: image {
                                altText
                                url
                              }
                              selectedOptions {
                                name
                                value
                              }
                              subscriptionFrequency: metafield(namespace: "custom", key: "subscription_frequency") {
                                value
                              }
                              product {
                                tags
                              }
                            }
                            originalTotalPrice {
                              amount
                            }
                            discountedTotalPrice {
                              amount
                            }
                            discountAllocations {
                              allocatedAmount {
                                amount
                              }
                              discountApplication {
                                allocationMethod
                                targetSelection
                                targetType
                                value {
                                  __typename
                                  ... on MoneyV2 {
                                    amount
                                  }
                                  ... on PricingPercentageValue {
                                    percentage
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      totalPrice {
                        amount
                      }
                      totalShippingPrice {
                        amount
                      }
                      subtotalPrice {
                        amount
                      }
                      totalTax {
                        amount
                      }
                      discountApplications(first: 250) {
                        edges {
                          node {
                            allocationMethod
                            targetSelection
                            targetType
                            value {
                              __typename
                              ... on MoneyV2 {
                                amount
                              }
                              ... on PricingPercentageValue {
                                percentage
                              }
                            }
                          }
                        }
                      }
                      successfulFulfillments(first: 250) {
                        trackingCompany
                        trackingInfo {
                          number
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          `;

          if (isBrowser) {
            const data = await shopifyClient.request(GET_ORDERS, {
              customerAccessToken: window.localStorage.getItem(
                shopifySettingsForCurrency.customerTokenKey
              ),
              first,
              last,
              after,
              before,
            });

            return data?.customer?.orders;
          }
          return null;
        },
        isAuthenticated: () => {
          if (!customer?.isAuthenticated && isBrowser) {
            // Check if the current page is related to account videos or currency is CAD
            const isCurrencyCAD = currency === LocaleCurrencyEnum.CAD;
            const baseLoginPath = isCurrencyCAD
              ? '/en-ca/account/login'
              : '/account/login';
            const redirectUrl = encodeURIComponent(
              window.location.pathname + window.location.search
            );
            // Don't treat the account root page as a redirect spot because login naturally will navigate to /account on authentication
            const redirectExistsAndIsNotAccountRoot =
              !redirectUrl.endsWith('%2Faccount'); // use endsWith to account for other locale prefixes
            const redirectQueryParamPair = redirectExistsAndIsNotAccountRoot
              ? `${LOGIN_REDIRECT_QUERY_PARAM_KEY}=${redirectUrl}`
              : '';

            // Construct the login URL based on the presence of a redirect URL and currency
            const loginUrl = redirectUrl
              ? `${baseLoginPath}?${redirectQueryParamPair}`
              : baseLoginPath;
            // Navigate to the login URL
            navigate(loginUrl);
            return false;
          }

          return true;
        },
        isProStaff: () =>
          customer?.isAuthenticated &&
          customer?.tags?.length > 0 &&
          customer.tags.find(tag => tag === PRO_PROGRAM_CUSTOMER_TAG),
        saveCustomer,
        shopifyClient,
        getMagentoSubscriptions,
        refreshCognitoIdToken,
        checkCognitoIdExpiry,
        renewCustomerAccessToken,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}

export default CustomerProvider;
