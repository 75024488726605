export const navSearchComponent = {
  key: 'navSearchComponent',
  name: 'Nav Search Component',
  siteIdentifier: 'global',

  hitsContainerAriaLabel: 'Search results container',
  screenReaderAnnouncementMessage:
    '{numberOfHits} suggestions found. To navigate use arrow keys',
  screenReaderAnnouncementNoResultsMessage: 'No suggestions found',
};
