const useSiteSettings = () => {
  const sanitySiteSettings = {};

  const defaultSettings = {
    shipping: 69,
    contactPage: '/contact-us',
    phone: '+1 877-847-3868',
    ordersPageHeadline: 'Your Orders',
    ordersPageSubHeadline: 'View your order history and manage your account.',
    subscriptionsPageHeadline: 'Subscriptions',
    subscriptionsPageSubHeadline:
      'Manage your subscriptions, update payment methods, and view subscription details here.',
    videosPageHeadline: 'Training Videos',
    videosPageSubHeadline:
      'Exclusive training videos to help you train your pet with Invisible Fence technology.',
    addressesPageHeadline: 'Your Addresses',
    addressesPageSubHeadline: 'Manage your delivery addresses here.',
    settingsPageHeadline: 'Your Account',
    settingsPageSubHeadline: 'Manage your account details and password here.',
  };

  const settings = { ...defaultSettings, ...sanitySiteSettings };

  // Ensure contactPage slug starts with '/'
  if (sanitySiteSettings?.contactPage?.slug?.current) {
    settings.contactPage =
      sanitySiteSettings.contactPage.slug.current.startsWith('/')
        ? sanitySiteSettings.contactPage.slug.current
        : `/${sanitySiteSettings.contactPage.slug.current}`;
  }

  return settings;
};

export default useSiteSettings;
