export const supportCardComponent = {
  key: 'supportCardComponent',
  name: 'Support Card Dept-Component',
  siteIdentifier: 'global',

  supportCardHeader: 'Support',
  productManualLink: 'Product Manual',
  moreSupportTopicsLink: 'See More Support Topics',
  itemModelNumberLabel: 'Item Model Number',
  warningsLabel: 'Warnings',
  warningLabel: 'Warning',
  productWarrantiesLabel: 'Product Warranties',
  productWarrantyLabel: 'Product Warranty',
};
