/* eslint-disable no-template-curly-in-string */
export const subscriptionCommon = {
  key: 'subscriptionCommon',
  name: 'Subscription Common',
  siteIdentifier: 'global',

  petname: 'Pet name will be assigned during installation',
  nextBillingDate: 'Next Billing Date',
  monthly: 'Monthly',
  biMonthly: 'Bi-Monthly',
  quarterly: 'Quarterly',
  every4Months: 'Every 4 Months',
  semiAnnually: 'Semi-Annually',
  yearly: 'Yearly',
  lteServiceExpires: 'LTE Service Expires',
  lteServiceExpired: 'LTE Service Expired',
  cancelSubscriptionButton: 'Cancel Subscription',
  cancelSubscriptionLink: 'Cancel Subscription',
  subscriptionCancelledMessage: 'Your subscription has been canceled.',
  notFoundSubscriptionMessage: 'Subscription was not found.',
  cancelYourSubscriptionQuestion: 'Cancel your subscription?',
  tellUsWhyCancellingFormSelectLabel:
    "Please help us process your request by telling us why you're cancelling.",
  selectOneDefaultFormSelectOption: 'Select one',
  noCollarTrackingWithoutSubscriptionMessage:
    'Collar tracking features will be deactivated without subscription.',
  keepSubscriptionButton: 'Keep Subscription',
  noCancelForInactiveSubscriptionMessage:
    'You cannot cancel non-active subscription.',
  tryAgainLaterOrContactSupportMessage:
    'Please try again later, or contact customer support.',
  paymentMethodUpdatedMessage: 'Your payment method has been updated.',
  paymentMethodNotUpdatedMessage:
    'Your payment method could not be updated at this time. Please try again later.',
  paymentMethodFormSelectLabel: 'Payment Method',
  paymentMethodText: 'Payment Method',
  paymentMethodExpiryDate: 'Exp {date}',
  nextBillAmountInfoMessage:
    ' This card will be charged {currency} {price} on your next billing date.',
  manageSubscriptionLink: 'Manage Subscription',
  managePaymentMethodsButton: 'Manage Payment Methods',
  updateLinkSentMessage: 'Update link sent.',
  updateLinkNotSentMessage:
    'Update link could not be sent at this time. Please try again later.',
  paymentMethodsHeader: 'Payment Methods',
  paymentMethodNotLoadedMessage:
    'Your payment methods could not be loaded at this time. Please try again later.',
  editPaymentMethodHeader: 'Edit Payment Method',
  expiresTextForPaymentMethod: 'Expires',
  paymentMethodUpdateEffectMessage:
    'Updating this payment method will update {associatedSubscriptionsLength} associated subscription(s):',
  paymentMethodUpdateInfoMessage: `Any updates to this payment method must be completed in
                  checkout. {sendUpdateLink} to receive an
                  email with a link to update this payment method. Please check
                  your spam folder if you don't receive the email within 10
                  minutes.`,
  sendUpdateLinkText: 'Send Update Link',
  sendUpdateLinkButton: 'Send Update Link',
  billingAddressText: 'Billing Address',
  associatedSubscriptionsListHeader: 'Associated Subscriptions',
  canceledTextForSubscriptionStatus: 'Canceled',
  savingButton: 'Saving',
  saveChangesButton: 'Save Changes',
  somethingWentWrongMessage: 'Something went wrong',
  previousText: 'Previous',
};

export const addressCommon = {
  key: 'addressCommon',
  name: 'Address Common',
  siteIdentifier: 'global',

  defaultAddress: '(Default Address)',
  makeDefaultButton: 'Make Default',
  addNewAddressButton: 'Add New Address',
  addressDeletionConfirmationText:
    'Are you sure you would like to delete this address?',
  phoneLabelInAccountAddress: 'Phone:',
  companyLabel: 'Company',
  companyNameLabel: 'Company Name',
  addressLine1Label: 'Address Line 1',
  addressLine2Label: 'Address Line 2',
  cityLabel: 'City',
  countryLabel: 'Country',
  stateProvinceLabel: 'State/Province',
  zipPostcodeLabel: 'ZIP/Postcode',
  chooseCountryDefaultSelectOption: 'Choose Country',
  unitedStatesSelectOption: 'United States',
  canadaSelectOption: 'Canada',
  saveAddressButton: 'Save Address',
  somethingWentWrongMessage: 'Something went wrong',
};

export const navigationCommon = {
  key: 'navigationCommon',
  name: 'Navigation Common',
  siteIdentifier: 'global',

  trainingVideosLink: 'Training Videos',
  addressesLink: 'Addresses',
  accountSettingsLink: 'Account Settings',
  signOutLink: 'Sign out',
  eligibleForDiscountsMessage:
    'Your account is eligible for ProStaff Discounts!',
  learnMoreAboutBrandsAriaLabel: 'Learn more about our brands',
  learnMoreButton: 'Learn more',
  closeModalAriaLabel: 'Close Modal',
  closeModalButton: 'Close',
  oneAccountForEverythingPetNeedsHeader:
    'One Account for Everything your Pet Needs',
  oneAccountAcrossManyBrandsParagraph: `Your {brand} account can also be used across our family of brands:`,
  invisibleFenceBrandLink: 'Invisible Fence Brand',
  invisibleFenceBrandWebsiteAriaLabel: 'Invisible Fence Brand Website',
  kurgoLink: 'Kurgo',
  kurgoWebsiteAriaLabel: 'Kurgo Website',
  petSafeLink: 'PetSafe',
  petSafeWebsiteAriaLabel: 'PetSafe Website',
  sportDogLink: 'SportDOG',
  sportDogWebsiteAriaLabel: 'Sport Dog Website',
  myAccountHeader: 'My Account',
  manageAccountSettingsAndDataParagraph: 'Mange your account settings and data',
  subscriptionsLink: 'Subscriptions',
};

export const orderCommon = {
  key: 'orderCommon',
  name: 'Order Common',
  siteIdentifier: 'global',

  viewOrderButton: 'Order',
  orderHeader: 'Order',
  ordersLink: 'Orders',
  productCountAndPriceParagraph: '{productCount} {products} totaling {price}',
  productWordSingular: 'product',
  productWordPlural: 'products',
  orderPlacedLabel: 'Order Placed:',
  beforeLabel: 'Before:',
  freeAfterDiscountInfoMessage: 'After Discount: FREE',
  afterLabel: 'After:',
  autoReNewEveryMonth: 'Auto-renews every month',
  autoReNewEvery12Month: 'Auto-renews every 12 months',
  autoReNewEveryIntervalMonth: 'Auto-renews every {interval} months',
  orderWithSubscriptionItemMessage:
    'Please {message} if you have any questions or need to modify/cancel your subscription.',
  giveUsCallAt: 'give us a call at {phoneNumber} or ',
  sendUsMessageLink: 'send us a message',
  orderWithLteSubscriptionItemMessage: `To manage your GPS Tracking Plan, including changing your payment
          method and cancelling your subscription, go to the {link} section of your Account.`,
  locationTrackingSubscriptionsLink: 'Location/Tracking Subscriptions',
  shopifyImageDiscontinuedImageAlt: '{title} discontinued',
  quantityAtPriceEach: '{quantity} at {price} each',
  deliveryEveryIntervalMonths: 'Delivery every {interval} months',
  subTotalHeaderLabel: 'Subtotal:',
  shippingHeaderLabel: 'Shipping:',
  shippingFreeHeaderText: 'Free',
  taxHeaderLabel: 'Tax:',
  discountsHeaderLabel: 'Discount(s):',
  freeShippingDiscountHeaderText: 'Free Shipping',
  discountDescription: '{value} OFF',
  prostaff40PercentDiscount: ' 40% ProStaff',
  grandTotalHeaderLabel: 'Grand Total:',
  orderDetailsHeader: 'Order Details',
  orderStatusLabel: 'Order Status:',
  orderDateLabel: 'Order Date:',
  shippingMethodLabel: 'Shipping Method:',
  trackingLabel: 'Tracking:',
  trackingLinkAriaLabel: 'Tracking link',
  fulfillmentByLabel: 'Fulfillment by:',
  printInvoiceButton: 'Print Invoice',
  shipToHeader: 'Ship To',
  productImageAlt: 'Product Image',
  noImageAvailableAlt: 'No image available',
  shouldThisBeDefaultAddressQuestion:
    'Are you sure you would like to make this your default address?',
  accountPasswordUpdatedSuccessfullyMessage:
    'Account Password updated successfully.',
  somethingWentWrongMessage: 'Something went wrong',
  previousButtonLabel: 'Previous',
  orderNotPlacedDefaultMessage:
    "You haven't placed any orders with us. When you do, their status will appear on this page.",
};

export const accountSettingsCommon = {
  key: 'accountSettingsCommon',
  name: 'Account Settings Common',
  siteIdentifier: 'global',

  userDetailsHeader: 'User Details',
  updateDetailsButton: 'Update Details',
  updatePasswordHeader: 'Update Password',
  updatePasswordButton: 'Update Password',
};

export const layoutComponent = {
  key: 'layoutComponent',
  name: 'Layout Component',
  siteIdentifier: 'global',

  backLayoutButton: 'Back',
};

export const accountFormCommon = {
  key: 'accountFormCommon',
  name: 'Account Form Common',
  siteIdentifier: 'global',

  firstNameLabel: 'First Name',
  lastNameLabel: 'Last Name',
  phoneNumberLabel: 'Phone Number',
  phoneNumPlaceholder: 'Enter phone number',
  currentPasswordLabel: 'Current Password',
  newPasswordLabel: 'New Password',
  eightCharacterPwdHint: 'Password must contain at least 8 characters',
  confirmNewPasswordLabel: 'Confirm New Password',
  choosePwdWith8CharsValidationMessage:
    'Please choose a password with at least 8 characters',
  passwordLabel: 'Password',
  passwordsMustMatchValidationMessage: 'Passwords must match',
  confirmPasswordLabel: 'Confirm Password',
  fieldRequiredValidationMessage: '{label} is required',
  fieldInvalidValueValidationMessage: '{label} is invalid',
};
