export const calculatePercentageSavings = ({
  compareAtPrice,
  price,
}: {
  compareAtPrice: number;
  price: number;
}) => Math.round(((compareAtPrice - price) / compareAtPrice) * 100);

// Use in case of displaying prices for a specific currency
export const currencyFormatter = (currencyCode?: string) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode || 'USD',
  });

// Convert a date to a  MMM dd, yyyy format. e.g. May 10, 2022
export const formatDate = (date?: string, long?: boolean) =>
  date
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: long === true ? 'long' : 'short',
        day: '2-digit',
      }).format(new Date(date))
    : '';
