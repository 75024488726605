export const contactFormComponent = {
  key: 'contactFormComponent',
  name: 'Contact Form Component',
  siteIdentifier: 'global',

  productQuestionLabel:
    'Which product do you have a question about? (optional)',
  pleaseChooseDefaultSelectOption: 'Please Choose',
  questionOrCommentLabel: 'Question or Comment',
  proveYouAreNotRobot: 'Please check the box to prove you are not a robot!',
  questionLabel: 'Question',
  phoneNumberLabel: 'Phone Number',
  allFieldsRequired: 'Unless indicated, all fields are required',
  nameLabel: 'Name',
  emailLabel: 'Email',
  submitButton: 'Submit',
};

export const formMessageBoxComponent = {
  key: 'formMessageBoxComponent',
  name: 'Form Message Box Component',
  siteIdentifier: 'global',

  enableCookiesForLoginMessage:
    'There was a problem logging in, please check that your browser is set to allow 3rd-party cookies for this site.',
};
