export const defaultFormValidationMessagesCommon = {
  key: 'defaultFormValidationMessagesCommon',
  name: 'Default Form Validation Messages Common',
  siteIdentifier: 'global',

  defaultFieldRequiredValidationMessage: '{label} is a required field',
  defaultMaxLengthConstraintValidationMessage:
    '{label} must be at most {length} characters',
  defaultMinLengthConstraintValidationMessage:
    '{label} must be at least {length} characters',
  defaultEmailValidationMessage: '{label} must be a valid email',
  defaultFieldInvalidValidationMessage: 'Invalid value for {label}',
};
