export const newsLetterComponent = {
  key: 'newsLetterComponent',
  name: 'News Letter Dept-Component',
  siteIdentifier: 'global',

  newsletterHeader: 'Join our Newsletter',
  newsletterDescription:
    'Sign up for our newsletter and save 10% on your first order!',
  signUpNowButton: 'Sign Up Now',
};
