import { atoms } from './atoms';
import { layout } from './layout';
import { molecules } from './molecules';
import { organisms } from './organisms';
import { utilsDeptLabels } from './utilsDeptLabels';

export const dept = {
  ...atoms,
  ...layout,
  ...molecules,
  ...organisms,
  utilsDeptLabels,
};
