import PropTypes from 'prop-types';
import slugify from 'slugify';

export const LinkShape = PropTypes.shape({
  linkType: PropTypes.string,
  href: PropTypes.string,
  internalLink: PropTypes.shape({}),
});

export const getCollectionLink = title => {
  const newSlug = title
    ?.split('/')
    .map((level: string) =>
      slugify(level, { lower: true, strict: true, trim: true })
    )
    .join('/');

  const pathname = `/shop/${newSlug}`;

  if (pathname.includes('all-products')) {
    return pathname.replace('/all-products', '');
  }
  return pathname;
};

const getLink = (
  type: string,
  href: string,
  internalLink: { slug?: any; _type?: any; store?: any }
) => {
  if (type === 'external') {
    return href;
  }
  if (type === 'internal') {
    try {
      switch (internalLink?._type) {
        case 'page':
          if (internalLink?.slug?.current === '/homepage') {
            return `/`;
          }
          return `/${internalLink.slug.current}`;

        case 'globalProduct':
        case 'product':
          return `/product/${internalLink.store.slug.current}`;

        case 'pressRelease':
          return `/press/${internalLink.slug.current}`;

        case 'blogPost':
          return `/blog/${internalLink.slug.current}`;

        case 'blogCategory':
          return `/blog/${internalLink.slug.current}`;

        case 'globalProductCollection':
        case 'collection':
          return getCollectionLink(internalLink?.store?.title);

        default:
          return `/${internalLink?.slug?.current}`;
      }
    } catch (e) {
      console.error('Get internal link error', e);
      return undefined;
    }
  }
  return undefined;
};

export default getLink;
