export const headerCommon = {
  key: 'headerCommon',
  name: 'Header Common',
  siteIdentifier: 'global',

  searchPlaceholder: 'Search',
  cartIconTitle: 'Cart',
  cartAriaLabel: 'Cart',
  linkToHomePageAriaLabel: 'Go to homepage',
  linkToBrandHomePageAriaLabel: '{brandName} - Go to homepage',
  mobileNavigationHamburgerLabel: 'Mobile Navigation',
  myAccountLink: 'My Account',
  myAccountAriaLabel: 'My Account',
  yourLocalDealerLink: 'Your Local Dealer',
  shopAllTitleLink: 'Shop All {title}',
  showAllResultsForQueryLink: `Show all results for "{query}"`,
  searchIsCurrentlyUnavailableMessage: 'Search is currently unavailable',
  productsHeader: 'Products',
};
