export const notificationBannerCommon = {
  key: 'notificationBannerCommon',
  name: 'Notification Banner Common',
  siteIdentifier: 'global',

  countryPickerAriaLabel: 'Country Picker',
  chooseYourLocationHeader: 'Choose your location',
  chooseYourLocationForCorrectInfoMessage: `To make sure you're seeing the correct availability and
        pricing for products and services, please choose your location:`,
  canadaCadLabel: 'Canada (CAD)',
  unitedStatesUsdLabel: 'United States (USD)',
  setLocationButton: 'Set Location',
  liveChat: 'Live Chat',
  replaceWithMessageFromBackendMessage:
    'Replace this with a message from the backend',
};
