export const shopifyOrderFulfillmentStatus = {
  key: 'shopifyOrderFulfillmentStatus',
  name: 'Shopify Order Fulfillment Status',
  siteIdentifier: 'global',

  FULFILLED: 'FULFILLED',
  IN_PROGRESS: 'IN PROGRESS',
  ON_HOLD: 'ON HOLD',
  OPEN: 'OPEN',
  PARTIALLY_FULFILLED: 'PARTIALLY FULFILLED',
  PENDING_FULFILLMENT: 'PENDING FULFILLMENT',
  RESTOCKED: 'RESTOCKED',
  SCHEDULED: 'SCHEDULED',
  UNFULFILLED: 'UNFULFILLED',
};

export const shopifyOrderFinancialStatus = {
  key: 'shopifyOrderFinancialStatus',
  name: 'Shopify Order Financial Status',
  siteIdentifier: 'global',

  AUTHORIZED: 'AUTHORIZED',
  PAID: 'PAID',
  PARTIALLY_PAID: 'PARTIALLY PAID',
  PARTIALLY_REFUNDED: 'PARTIALLY REFUNDED',
  PENDING: 'PENDING',
  REFUNDED: 'REFUNDED',
  VOIDED: 'VOIDED',
};
