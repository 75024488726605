export const regionsListCommon = {
  key: 'regionsListCommon',
  name: 'Regions List Common',
  siteIdentifier: 'global',

  Americas: 'Americas',
  Europe: 'Europe',
  Oceania: 'Oceania',
  Nordic: 'Nordic',
};
