export const subscriptionActivationDialog = {
  key: 'subscriptionActivationDialog',
  name: 'Subscription Activation Dialog',
  siteIdentifier: 'global',

  activationCodeRequiredMessage: 'Activation code is required',
  emailInvalidMessage: 'Please re-check your email address.',
  emailRequiredMessage: 'Email address is required',
  emailValidRequiredMessage:
    'Please use a valid email address such as user@example.com',
  activationCodeLabel: 'Activation Code',
  emailCodeLabel: 'Email',
  applyActivationButtonAriaLabel: 'Apply Activation Submit Button',
  applyButton: 'Apply',
};
