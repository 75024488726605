export const subscriptionCancelModalComponent = {
  key: 'subscriptionCancelModalComponent',
  name: 'Subscription Cancel Modal Dept-Component',
  siteIdentifier: 'global',

  cancelHeader: 'Cancel',
  pauseLabel: 'Would you like to pause instead?',
  upcomingShipmentLabel: `Your upcoming shipment for {oldDay} will be rescheduled for {newDay}`,
  cancelSubscriptionButton: 'Cancel Subscription',
  pauseSubscriptionButton: 'Pause Subscription',
  weekLabel: '1 Week',
  monthLabel: '1 Month',
  monthsLabel: '3 Months',
  pausedCompleteToastTitle: 'Your subscription has been paused',
  pausedNotCompleteToastTitle: 'We could not complete your request at this time. Please try again later.',
};
