export const footerLayoutComponent = {
  key: 'footerLayoutComponent',
  name: 'Footer Layout Dept-Component',
  siteIdentifier: 'global',

  connectLabel: 'Connect',
  rightsLabel: 'All Rights Reserved.',
  cookieButton: 'Cookie Settings',
  cookieSettingsModalAriaLabel: 'Open cookie settings modal',
};
