/* eslint-disable no-template-curly-in-string */
export const productCommon = {
  key: 'productCommon',
  name: 'Product Common',
  siteIdentifier: 'global',

  salePrice: 'SALE {amount}',
  usBasedCustomerCare: 'US-based customer care',
  localDealerNetwork: 'Local dealer network',
  customerCareMessageForUS: '{entity} team providing expert product support',
  customerCareMessageForLocal: '{entity} team providing expert product support',
  orderToReceiveBetweenDates: '{orderToday} to receive between {from}-{to}',
  orderToday: 'Order today',
  freeProfessionalInstallation: 'Free Professional Installation',
  onOrdersOver: 'on orders over {price}',
  qualifiesForFreeShipping: 'This product qualifies for {freeShippingLink}',
  freeShippingLink: 'free shipping!',
  freeShippingInCap: 'Free shipping',
  freeShippingInCapLink: 'Free shipping',
  inStock: 'In Stock',
  outOfStock: 'Out of Stock',
  availabilityLabel: 'Availability:',
  quantityLabel: 'Quantity',
  comingSoon: 'Coming Soon',
  discontinued: 'Discontinued',
  addToCartAriaLabel: 'Add to Cart',
  addToCartButton: 'Add to Cart',
  productManualLink: 'Product Manual',
  visitSupportSiteLink: 'Visit support site',
  oneYearMoneyBackGuarantee: '1 year money-back guarantee',
  expandToFullscreenAriaLabel: 'Expand to Fullscreen',
  previousSlideAriaLabel: 'Previous Slide',
  nextSlideAriaLabel: 'Next Slide',
  unmuteVideoAriaLabel: 'Unmute Video',
  muteVideoAriaLabel: 'Mute Video',
  pauseVideoAriaLabel: 'Pause Video',
  playVideoAriaLabel: 'Play Video',
  howToMeasureLink: 'How to Measure',
  selectNamePlaceholder: 'Select {name}',
  selectNameOption: 'Select {name}',
  chooseAvailableOptionValidationMessage: 'Choose an available {option}',
  colorAriaLabel: 'Color:',
  colorsAriaLabel: 'Colors',
  colorPickerLabel: 'Color',
};
