export const referralProgramComponent = {
  key: 'referralProgramComponent',
  name: 'Referral Program Component',
  siteIdentifier: 'global',

  referralLinkLabel: 'Referral link',
  copiedToClipboardMessage: '{label} copied to clipboard!',
  couldNotCopyToClipboardErrorMessage:
    '{label} could not be copied to clipboard! Please try again.',
  yourUniqueReferralCodeDescription: 'Your unique referral code',
  referralCodeLabel: 'Referral Code',
  copyLinkButton: 'Copy Link',

  emailOutletName: 'Email',
  smsOutletName: 'SMS',
  facebookOutletName: 'Facebook',
  twitterOutletName: 'Twitter',
  linkedInOutletName: 'LinkedIn',

  emailOutletLabel: 'Share via Email',
  smsOutletLabel: 'Share via SMS',
  facebookOutletLabel: 'Share on Facebook',
  twitterOutletLabel: 'Share on X',
  linkedInOutletLabel: 'Share on LinkedIn',
};
