export const heroComponent = {
  key: 'heroComponent',
  name: 'Hero Component',
  siteIdentifier: 'global',

  unsupportedVideoTagFallbackText:
    'Your browser does not support the video tag.',
  videoPosterAlt: 'Video Poster',
  playBgHeroVideoAriaLabel: 'Play Video',
  pauseBgHeroVideoAriaLabel: 'Pause Video',
};
