import React from 'react';
import BreadcrumbWithWrapper from '@rsc/ui-components/BreadcrumbWithWrapper';
import { useUiComponentsInternationalContext } from '@rsc/static-translations/src';

const useAlgoliaSearchBreadcrumb = (nbHits, searchState) => {
  const { tt } = useUiComponentsInternationalContext();
  return (
    <BreadcrumbWithWrapper
      elements={[
        {
          text: tt(s => s.algoliaSearchBreadcrumbComponent.homeBreadcrumbLabel),
          link: '/',
        },
        {
          text: searchState.query
            ? tt(
                s =>
                  s.algoliaSearchBreadcrumbComponent
                    .searchResultsBreadcrumbLabel,
                {
                  results: nbHits,
                  query: searchState.query,
                }
              )
            : tt(
                s =>
                  s.algoliaSearchBreadcrumbComponent
                    .searchResultsWithoutQueryBreadcrumbLabel
              ),
          link: '/search',
        },
      ]}
    />
  );
};

export default useAlgoliaSearchBreadcrumb;
