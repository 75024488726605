import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import { CartProvider } from '@rsc/contexts/CartContext';
import { SiteSettingsProvider } from '@rsc/contexts/SiteSettingsContext';
import CustomerContextProvider from '@rsc/contexts/CustomerContext';
import useSiteSettings from '@components/hooks/useSiteSettings';

import { getTranslationCache } from '@rsc/sanity-static-localization-provider-library';
import {
  InternationalContext,
  useInternationalHelper,
} from '@rsc/gatsby-localization-helpers';
import { uiComponentsLocalizationData } from '@rsc/static-translations';

import theme from './src/components/theme';

const InternationalContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const mockTranslationCache = getTranslationCache(
    null,
    uiComponentsLocalizationData
  );

  const staticContentLocalizer = useInternationalHelper(
    'en-us',
    mockTranslationCache,
    uiComponentsLocalizationData
  );

  return (
    <InternationalContext.Provider value={staticContentLocalizer}>
      {children}
    </InternationalContext.Provider>
  );
};

function RootWrapper({ element }: { element: React.ReactElement }) {
  return (
    <ThemeProvider theme={theme}>
      <InternationalContextWrapper>
        <SiteSettingsProvider
          useSiteSettings={useSiteSettings}
          siteIdentifier="kurgo"
        >
          <CustomerContextProvider>
            <CartProvider>{element}</CartProvider>
          </CustomerContextProvider>
        </SiteSettingsProvider>
      </InternationalContextWrapper>
    </ThemeProvider>
  );
}

export default RootWrapper;
