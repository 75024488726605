export const productContainerDeptComponents = {
  key: 'productContainerDeptComponents',
  name: 'Product Container Dept-Components',
  siteIdentifier: 'global',

  productImageAlt: 'Product Image',
  placeholderImageAlt: 'Placeholder Image',
  fullScreenAriaLabel: 'full screen',
  previousAriaLabel: 'Previous',
  nextAriaLabel: 'Next',
  measureLabel: 'Measure',
  readLessLabel: 'Read Less',
  readMoreLabel: 'Read More',
  chooseAvailableLabel: 'Choose an available',
  scrollBackAriaLabel: 'Scroll back to product details',
  scrollToReviewsButtonAriaLabel: 'scroll to reviews',
  addToCartButton: 'Add to Cart',
  imageDefaultAlt: 'Placeholder Image',
};
