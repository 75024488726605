export const productAttributesComponent = {
  key: 'productAttributesComponent',
  name: 'Product Attributes Component',
  siteIdentifier: 'global',

  specialFeaturesHeader: 'Special Features',
  moreInformationHeader: 'More Information',
  rangeHeader: 'Range',
  stimulationRangesHeader: 'Stimulation Ranges',
  barkDetectionTypeHeader: 'Bark Detection Type',
  automaticShutoffHeader: 'Automatic Shutoff',
  expandableSystemHeader: 'Expandable System',
  remoteTransmitterHeader: 'Remote Transmitter',
  waterproofHeader: 'Waterproof',
  beeperOptionsHeader: 'Beeper Options',
  dogSizeHeader: 'Dog Size',
  beeperHeader: 'Beeper',
  batteriesHeader: 'Batteries',
  lowBatteryIndicatorHeader: 'Low Battery Indicator',
  yes: 'Yes',
  productLengthHeader: 'Product Length',
  systemCompatibilityHeader: 'System Compatibility',
  contentsHeader: 'Contents',
  productDimensionsHeader: 'Product Dimensions',
};
