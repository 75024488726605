import { GetInternalLinkFunctionType } from '@rsc/models/GetInternalLinkFunctionType';
import { LocaleCurrencyEnum } from '@rsc/models/LocaleCurrencyEnum';
import { getLocalizedPagePath } from '@rsc/gatsby-localization-helpers';

// Check if Anchor exists and add it to the url
const getSlugWithAnchor = (slug, anchor) =>
  anchor ? `${slug}#${anchor}` : slug;

const getTranslatedSlug = (slug, currency) => {
  let localeSlug = slug;

  if (currency === LocaleCurrencyEnum.CAD) {
    localeSlug = `/en-ca${localeSlug}`;
  }

  return localeSlug;
};

const getInternalLink: GetInternalLinkFunctionType = ({
  slug,
  currency = LocaleCurrencyEnum.USD,
  anchor,
  locale,
}) => {
  if (locale) {
    return getLocalizedPagePath(getSlugWithAnchor(slug, anchor), locale);
  }

  if (currency) {
    return getTranslatedSlug(getSlugWithAnchor(slug, anchor), currency);
  }

  return slug;
};

export default getInternalLink;
