export const shadcnCommon = {
  key: 'shadcnCommon',
  name: 'shadcn Common',
  siteIdentifier: 'global',

  carouselAriaLabel: 'Carousel',
  carouselAriaRoleDescription: 'Carousel',
  carouselSectionAriaLabel: 'Carousel Section',
  slideAriaRoleDescription: 'slide',
  dialogCloseButton: 'Close',
  previousSlideButton: 'Previous slide',
  nextSlideButton: 'Next slide',
  paginationPreviousAriaLabel: 'Go to previous page',
  paginationPreviousLabel: 'Previous',
  paginationNextAriaLabel: 'Go to next page',
  paginationNextLabel: 'Next',
  paginationEllipsisLabel: 'More pages',
  paginationAriaLabel: 'Pagination',
};
