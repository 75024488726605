export const newsletterModalComponent = {
  key: 'newsletterModalComponent',
  name: 'News Letter Modal Dept-Component',
  siteIdentifier: 'global',

  thanksForSignToastTitle: 'Thanks for signing up!',
  checkInboxToastDescription: `Check your inbox to confirm your subscription (required). Expect a 
message from us shortly and click the link to verify your email 
address.`,
  checkSpamFolderToastLabel: `Also check the Spam folder if you do not see a confirmation email 
in your inbox.`,
  importantNoticeToastLabel: 'Important:',
  errorMessage:
    'Form submission failed. Please refresh the page or try again later.',
  signUpUnsuccessfulMessage: 'Sign up unsuccessful',
  emailUpdatesLabel: 'Get Email Updates',
  firstNamePlaceholderLabel: 'Enter your first name',
  emailPlaceholderLabel: 'Enter your email address',
  sendInformationLabel: 'Send me helpful information about:',
  selectInterestLabel: 'Please select an interest',
  signUpButton: 'Sign Up',
  agreementText: `By submitting this form, I agree to let PetSafe\xAE brand
send me news about pets and pet ownership, product
promotions and other helpful information. To learn more
about our data use policies, see our`,
  privacyPolicyLabel: 'privacy policy',
  emailValidationMessage: 'Please re-check your email address.',
  emailRequiredValidationMessage: 'Email address required',
  firstNameRequiredValidationMessage: 'First name required',
  catOption: 'Cat',
  dogOption: 'Dog',
};
