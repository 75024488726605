export const productsPage = {
  key: 'productsPage',
  name: 'Products Page',
  siteIdentifier: 'global',

  dogTitle: 'Dog',
  catTitle: 'Cat',
  priceTitle: 'Price',
  petTypeTitle: 'Pet Type',
};
