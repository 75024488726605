import { blogAuthorCardComponent } from './blogAuthorCardComponent';
import { blogExpertBadgeComponent } from './blogExpertBadgeComponent';
import { navAccountButtonComponent } from './navAccountButtonComponent';
import { navCartButtonComponent } from './navCartButtonComponent';
import { outOfStockFormComponent } from './outOfStockFormComponent';
import { prevNextBtnComponent } from './prevNextBtnComponent';
import { priceComponent } from './priceComponent';
import { quantityCounterComponent } from './quantityCounterComponent';
import { reviewCardComponent } from './reviewCardComponent';
import { searchInputComponent } from './searchInputComponent';
import { toggleVideoButtonComponent } from './toggleVideoButtonComponent';
import { marketingCalloutComponent } from './marketingCalloutComponent';
import { socialLinksMessageComponent } from './socialLinksMessageComponent';

export const atoms = {
  blogAuthorCardComponent,
  blogExpertBadgeComponent,
  navAccountButtonComponent,
  navCartButtonComponent,
  outOfStockFormComponent,
  prevNextBtnComponent,
  priceComponent,
  quantityCounterComponent,
  reviewCardComponent,
  searchInputComponent,
  socialLinksMessageComponent,
  toggleVideoButtonComponent,
  marketingCalloutComponent,
};
