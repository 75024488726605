export const locationListDeptComponents = {
  key: 'locationListDeptComponents',
  name: 'Location List Dept-Components',
  siteIdentifier: 'global',

  orLabel: 'or',
  zipPlaceholder: 'Zip',
  parkNamePlaceholder: 'Park name',
  showMoreButton: 'Show More...',
  filterByLabel: 'Filter by',
  searchLabel: 'Search',
};
