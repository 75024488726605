export const contactFormDeptComponent = {
  key: 'contactFormDeptComponent',
  name: 'Contact Form Dept-Component',
  siteIdentifier: 'global',

  subjectText: 'Contact Form - {firstName} {lastName} {outletLabel}',
  firstNameLabel: 'First Name',
  lastNameLabel: 'Last Name',
  emailAddressLabel: 'Email Address',
  outletNameLabel: 'Outlet Name',
  messageLabel: 'Message',
  submitButton: 'Submit',
  firstNameRequiredValidationMessage: 'First name required',
  firstNameValidationMessage: 'First name must be at least 2 letters.',
  lastNameValidationMessage: 'Last Name must be at least 2 letters.',
  emailRequiredValidationMessage: 'Email address required',
  emailValidationMessage: 'Please re-check your email address.',
  outletNameValidationMessage: 'Outlet Name must be at least 2 letters.',
  messageRequiredValidationMessage: 'Message required',
  messageLengthValidationMessage: 'Message must be at least 15 characters.',
};
