const blogs = {
  newestToOldestLabel: 'Newest to oldest',
  oldestToNewestLabel: 'Oldest to newest',
  postLabel: '1 Post',
  postsLabel: '{count} Posts',
};

const products = {
  lowToHighLabel: 'Price low to high',
  highToLowLabel: 'Price high to low',
  productLabel: '1 Product',
  productsLabel: '{count} Products',
};

const searchPagination = {
  ariaPreviousPaginationButton: 'Previous page',
  ariaNextPaginationButton: 'Next page',
  ariaFirstPaginationButton: 'First page',
  ariaLastPaginationButton: 'Last page',
  pageLabel: 'Page {currentRefinement}',
};

export const searchLayoutComponent = {
  key: 'searchLayoutComponent',
  name: 'Search Layout Dept-Component',
  siteIdentifier: 'global',

  featuredLabel: 'Featured',
  sortLabel: 'Sort by',
  ...blogs,
  ...products,
  ...searchPagination,
};
