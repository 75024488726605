import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LocalizedLink } from '@rsc/gatsby-localization-helpers';
import { useUiComponentsInternationalContext } from '@rsc/static-translations';
import BreadcrumbArrow from './svgs/breadcrumb-arrow.svg';

const StyledBreadcrumb = styled.div`
  display: inline;
  color: '#5d5d5d';
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;
// Takes in array of {text, link} or {text} if last element
function Breadcrumb({ elements, useSVG }) {
  const { tt } = useUiComponentsInternationalContext();

  if (!elements || elements.length === 0) {
    return null;
  }

  return (
    <nav aria-label={tt(s => s.breadcrumbComponent.breadcrumbAriaLabel)}>
      <ol className="list-none p-0 m-0">
        {elements.map(({ text, link }, index) =>
          elements.length !== index + 1 ? (
            <li key={text} className="inline">
              <StyledBreadcrumb key={text}>
                <LocalizedLink to={`${link}`}>{text}</LocalizedLink>
                {useSVG ? (
                  <BreadcrumbArrow aria-hidden />
                ) : (
                  <span aria-hidden>{' > '}</span>
                )}
              </StyledBreadcrumb>
            </li>
          ) : (
            <li key={text} className="inline">
              <StyledBreadcrumb>
                <span>{text}</span>
              </StyledBreadcrumb>
            </li>
          )
        )}
      </ol>
    </nav>
  );
}

Breadcrumb.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  useSVG: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  useSVG: false,
};

export default Breadcrumb;
