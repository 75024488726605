export const searchInputComponent = {
  key: 'searchInputComponent',
  name: 'Search Input Dept-Component',
  siteIdentifier: 'global',

  resultsLabel: ` Show all results for "{query}"`,
  noResultsLabel: 'No results',
  searchUnavailableLabel: 'Search is currently unavailable',
  productsLabel: 'Products',
  blogsLabel: 'Learn',
};
