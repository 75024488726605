import { addToCartButtonComponent } from './addToCartButtonComponent';
import { contactFormDeptComponent } from './contactFormDeptComponent';
import { locationCardComponent } from './locationCardComponent';
import { mediaBackgroundComponent } from './mediaBackgroundComponent';
import { miscNewsComponent } from './miscNewsComponent';
import { mobileNavComponent } from './mobileNavComponent';
import { newsLetterComponent } from './newsLetterComponent';
import { newsletterModalComponent } from './newsletterModalComponent';
import { pressCardComponent } from './pressCardComponent';
import { productOptionsComponent } from './productOptionsComponent';
import { socialIconsComponent } from './socialIconsComponent';
import { subscriptionCancelModalComponent } from './subscriptionCancelModalComponent';
import { subscriptionLegacyModalComponent } from './subscriptionLegacyModalComponent';
import { subscriptionLteBillingComponent } from './subscriptionLteBillingComponent';
import { subscriptionPaymentMethodComponent } from './subscriptionPaymentMethodComponent';
import { subscriptionsCardComponent } from './subscriptionsCardComponent';
import { supportCardComponent } from './supportCardComponent';

export const molecules = {
  addToCartButtonComponent,
  contactFormDeptComponent,
  locationCardComponent,
  mediaBackgroundComponent,
  miscNewsComponent,
  mobileNavComponent,
  newsLetterComponent,
  newsletterModalComponent,
  pressCardComponent,
  productOptionsComponent,
  socialIconsComponent,
  subscriptionCancelModalComponent,
  subscriptionLegacyModalComponent,
  subscriptionLteBillingComponent,
  subscriptionPaymentMethodComponent,
  subscriptionsCardComponent,
  supportCardComponent,
};
