export const verifyAccountComponent = {
  key: 'verifyAccountComponent',
  name: 'Verify Account Component',
  siteIdentifier: 'global',

  requestNewVerificationLinkButton:
    'Click here to request a new verification link',
  invalidVerificationCodeMessage:
    'Invalid verification code provided, please try again.',
  expiredVerificationCodeMessage: 'Invalid or expired code provided.',
};
