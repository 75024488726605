export const subscriptionsCardComponent = {
  key: 'subscriptionsCardComponent',
  name: 'Subscription Card Dept-Component',
  siteIdentifier: 'global',

  nextOrderDateLabel: 'Next Order Date',
  shippingAddressLabel: 'Shipping Address',
  manageSubscriptionLabel: 'Manage Subscription',
  subscriptionExpiresLabel: 'Expires',
};
