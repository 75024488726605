import React from 'react';
import styled from 'styled-components';
import sharedStyles from '@rsc/ui-components/styles';
import Breadcrumb from './Breadcrumb';
import breakpoints from './breakpoints';

const BreadcrumbWrapper = styled.div<{ hideOnMobile: boolean }>`
  padding: 0 1.75rem;
  padding-bottom: 0;

  a,
  svg {
    color: ${({ theme }) => theme.colors.breadcrumbLinkColor};
    font-weight: normal;
    font-size: ${sharedStyles.fontSize.base};
    line-height: 2;
  }
  span {
    color: ${({ theme }) => theme.colors.cardText};
    font-weight: normal;
    font-size: ${sharedStyles.fontSize.base};
    line-height: 2;
  }

  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};

  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

export interface BreadcrumbElement {
  link?: string;
  text: string;
}

export interface BreadcrumbWithWrapperProps {
  elements: BreadcrumbElement[];
  hideOnMobile?: boolean;
}

function BreadcrumbWithWrapper({
  elements,
  hideOnMobile = false,
}: Readonly<BreadcrumbWithWrapperProps>) {
  return (
    <BreadcrumbWrapper hideOnMobile={hideOnMobile}>
      <Breadcrumb elements={elements} />
    </BreadcrumbWrapper>
  );
}

export default BreadcrumbWithWrapper;
