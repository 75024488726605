/* eslint-disable no-template-curly-in-string */
export const cartCommon = {
  key: 'cartCommon',
  name: 'Cart Common',
  siteIdentifier: 'global',

  shoppingCartWithQuantity: 'Shopping Cart ({totalQuantity})',
  closeCartAriaLabelledBy: 'close cart',
  closeCartButtonTitle: 'close cart',
  closeAlt: 'close',
  fulfilledBy: 'Fulfilled by',
  noItemsInShippingCartHeader: 'YOU HAVE NO ITEMS IN YOUR SHOPPING CART.',
  continueShoppingButton: 'Continue Shopping',
  continueShoppingToastButton: 'CONTINUE SHOPPING',
  checkoutSummarySubtotal: 'Subtotal',
  taxShippingCalculatedInCheckout: 'Tax and shipping calculated in checkout',
  secureCheckoutButton: 'Secure Checkout',
  lineItemThumbnailAlt: 'Thumbnail - {title}',
  priceEach: '{price} each',
  orderQualifiesForFreeShippingMessage:
    'This order qualifies for free shipping!',
  closeToFreeShippingMessage: "You're only {price} away from free shipping!",
  itemAddedToCart: 'Item Added To Cart',
  closeCartNotificationAriaLabelledBy: 'close cart notification',
  closeCartNotificationButtonTitle: 'close cart notification',
  viewCartButton: 'View Cart',
};
