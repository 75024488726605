export const productOptionsComponent = {
  key: 'productOptionsComponent',
  name: 'Product Options Dept-Component',
  siteIdentifier: 'global',

  purchasedLabel: 'Commonly purchased with',
  addToCartButton: 'Add to Cart',
  viewSizingChartLink: 'view sizing chart',
  productAddOnCheckboxAreaLabel: 'Product add-on checkbox',

  orderTodayLabel: 'Order today to receive between {range}',
  qualifiesForFreeShippingLabel: 'This product qualifies for {link}',
  freeShippingLink: 'Free Shipping',
  onOrdersOverLabel: '{link} on orders over {shipping}',
  satisfactionGuaranteedLabel: 'Satisfaction Guaranteed',
  returnLink: 'Return your product',
  withinLabel: 'within {returnDays} days',

  selectAutoshipLabel: 'Select {autoshipLabel} to get',
  autoshipLabel: 'Autoship',

  percentageDiscountPurchaseLabel: `{percentageDiscount}% off each purchase plus free
shipping`,
  saveLabel: 'Save {percentageDiscount}% + free shipping',
  oneTimeShipmentLabel: 'One-Time Shipment',
  deliveryFrequencyLabel: 'Delivery Frequency:',

  pleaseSelectLabel: 'Please Select',
  savingsLabel: '({percentageDiscount}% Savings)',

  selectLabel: 'Select',
  toGetLabel: 'to get',
  withDeliveryLabel: 'with each delivery!',

  setToSubscriptionLabel: 'Set to subscription order with autoship',
  setToSubscriptionAriaLabel: 'Set to subscription order with autoship',
  setOneTimeShipmentAriaLabel: 'Set order to one-time shipment',
  deliveryFrequencyDescriptionAriaLabel:
    'Delivery frequency for subscription autoship orders',
  pleaseSelectPlaceholderLabel: 'Please Select',

  outOfStockButton: 'Out of Stock',
  discontinuedButton: 'Discontinued',
  comingSoonButton: 'Coming Soon',
  modelIDLabel: 'Model ID: {sku}',

  IFBMessageLabel: `{care} team providing
expert product support`,
  USCustomerCare: 'US-based customer care',
  selectAFrequencyErrorMessage: 'Please select a frequency',
};
