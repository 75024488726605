export const countriesListCommon = {
  key: 'countriesListCommon',
  name: 'Countries List Common',
  siteIdentifier: 'global',

  australia: 'Australia',
  austria: 'Austria',
  belgium: 'Belgium',
  canada: 'Canada',
  czechia: 'Czechia',
  denmark: 'Denmark',
  finland: 'Finland',
  france: 'France',
  germany: 'Germany',
  hongKong: 'Hong Kong SAR',
  ireland: 'Ireland',
  italy: 'Italy',
  japan: 'Japan',
  netherlands: 'Netherlands',
  newZealand: 'New Zealand',
  portugal: 'Portugal',
  romania: 'Romania',
  singapore: 'Singapore',
  spain: 'Spain',
  sweden: 'Sweden',
  switzerland: 'Switzerland',
  unitedKingdom: 'United Kingdom',
  unitedStates: 'United States',
};
