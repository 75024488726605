import { accountDeptComponents } from './accountDeptComponents';
import { blocksDeptComponents } from './blocksDeptComponents';
import { cartDeptComponents } from './cartDeptComponents';
import { locationListDeptComponents } from './locationListDeptComponents';
import { navBarDeptComponents } from './navBarDeptComponents';
import { productContainerDeptComponents } from './productContainerDeptComponents';
import { productsPage } from './productsPage';
import { subscriptionActivationDialog } from './subscriptionActivationDialog';

export const organisms = {
  accountDeptComponents,
  blocksDeptComponents,
  cartDeptComponents,
  locationListDeptComponents,
  navBarDeptComponents,
  productContainerDeptComponents,
  productsPage,
  subscriptionActivationDialog,
};
