export const searchCommon = {
  key: 'searchCommon',
  name: 'Search Common',
  siteIdentifier: 'global',

  sortByLabel: 'Sort by:',
  featuredLabel: 'Featured',
  newestToOldestLabel: 'Newest to oldest',
  oldestToNewestLabel: 'Oldest to newest',
  pagesAtoZLabel: 'Pages A - Z',
  pagesZtoALabel: 'Pages Z - A',
  previousPageAriaLabel: 'Previous page',
  nextPageAriaLabel: 'Next page',
  firstPageAriaLabel: 'First page',
  lastPageAriaLabel: 'Last page',
  currentPageAriaLabel: 'Page {pageNumber}',
  searchIsUnavailableHeader: 'Search is currently unavailable',
  productCountSingular: '1 Product',
  productCountPlural: '{count} Products',
  articleCountSingular: '1 Article',
  articleCountPlural: '{count} Articles',
  pageCountSingular: '1 Page',
  pageCountPlural: '{count} Pages',
  priceLowToHighLabel: 'Price low to high',
  priceHighToLowLabel: 'Price high to low',
};
