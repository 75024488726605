export const navBarDeptComponents = {
  key: 'navBarDeptComponents',
  name: 'Nav Bar Dept-Components',
  siteIdentifier: 'global',

  shopAllProductsButton: 'Shop All {title} Products',
  allProductsLabel: 'All {title}',
  constantBarkerTitleLabel: 'From constant barker to peaceful pet',
  actionLabel: 'Call to action here',
};
