export const paginationCommon = {
  key: 'paginationCommon',
  name: 'Pagination Common',
  siteIdentifier: 'global',

  previousAriaLabel: 'Previous',
  prevButton: 'Prev',
  nextAriaLabel: 'Next',
  nextButton: 'Next',
};
