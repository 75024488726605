export const cartDeptComponents = {
  key: 'cartDeptComponents',
  name: 'Cart Dept-Components',
  siteIdentifier: 'global',

  thumbnailAlt: 'Thumbnail',
  removeButton: 'Remove',
  subscriptionDiscountBadgeLabel: `{discount}% Subscription Discount`,
  saveLabel: 'Subscribe and Save {discount}%',
  checkoutButton: 'Secure Checkout',
  continueShoppingButton: 'Continue Shopping',
  viewCartButton: 'View Cart',
  subtotalLabel: 'Subtotal',
  estimatedShippingLabel: 'Estimated Shipping',
  myCartLabel: 'My Cart',
  freeShippingLabel: 'This order qualifies for FREE SHIPPING!',
  notFreeShippingYetLabel:
    'Free Shipping on orders over {freeShippingThreshold}!',
  freeLabel: 'Free',
  noItemsMessage: 'You have no items in your cart.',
};
