export const articleComponent = {
  key: 'articleComponent',
  name: 'Article Component',
  siteIdentifier: 'global',

  postedBySubTitle: 'Posted by {author}',
  theSportDogStaff: 'The SportDOG Staff',
  moreAboutThisAuthorLink: 'More about this author',
  relatedProductsHeader: 'Related Products',
  relatedArticlesHeader: 'Related Articles',
  relatedArticleSubtitle: 'by {author}',
  articlesTaggedAriaLabel: 'articles tagged {title}',
};
