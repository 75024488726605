export const socialIconsComponent = {
  key: 'socialIconsComponent',
  name: 'Social Icons Dept-Component',
  siteIdentifier: 'global',

  instagramButton: 'Instagram Social Media Link',
  facebookButton: 'Facebook Social Media Link',
  xSocialMediaButton: 'X Social Media Link',
  pinterestButton: 'Pinterest Social Media Link',
  youTubeButton: 'YouTube Social Media Link',
  tikTokButton: 'TikTok Social Media Link',
};
