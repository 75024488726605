export const subscriptionLegacyModalComponent = {
  key: 'subscriptionLegacyModalComponent',
  name: 'Subscription Legacy Modal Dept-Component',
  siteIdentifier: 'global',

  accountViewUpdateLabel: 'Your new account view has improved your subscriptions:',
  betterManagementLabel: 'Better subscription management',
  streamlinedChoicesLabel: 'Streamlined frequency choices',
  improvedSecurityLabel: 'Improved security',
  confirmSubscriptionsLabel: `Please take a moment to confirm your current subscriptions and
update your payment information.`,
  confirmButton: 'Confirm',
  confirmLabel: 'Confirm your Subscriptions!',
  simplifiedSelectionsLabel: "We've simplified our frequency selections",
  confirmSubscriptionLabel: 'Confirm Subscription',
  productNoLongerSoldLabel: `This product is no longer sold by PetSafe online.
Please contact Customer Care at {phone}`,
  defaultProductSupportNumberValue: `1-800-732-2677`,
  contactCustomerCareLabel: 'to manage this subscription.',
  customerCareHoursLabel: `We are open Monday through Friday 8 am - 8 pm ET and
Saturday 9 am - 5 pm ET.`,
  frequencyLabel: 'Frequency:',
  secureCheckoutButton: 'Secure Checkout',
  useSecureCheckoutLabel: `Please use our Secure Checkout to confirm your payment
information`,
  notRightNowButton: 'Not Right Now',
};
